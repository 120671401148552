import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from '@mui/material';
import { useEffect } from 'react';

import CardStep from './component/cardstep';
import TermsOfService from './component/tos';
import Main from "./component/main";
import MenuComponent from './component/menu';
import Footer from './component/footer';
import Category from './component/catlist';
import CategoryDetail from './component/catid';
import Htgc from './component/htgc';

function App() {
  useEffect(() => {
    // Carica dinamicamente Google Analytics
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-27YNFPK270";
    document.head.appendChild(script1);

    // Inizializza Google Analytics
    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-27YNFPK270');
    `;
    document.head.appendChild(script2);
  }, []);

  return (
    <Router>
      <MenuComponent />
      <Container>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/card/:id/:hash?" element={<CardStep />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/category" element={<Category />} />
          <Route path="/category/:id" element={<CategoryDetail />} />
          <Route path="/how-to-get-coins" element={<Htgc />} />
        </Routes>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;
